:root {
  --background: rgba(0, 0, 0, 0.065);
  --color: rgba(0, 0, 0, 0.33);
  --color-clip: #BD2AB6;
  --aux-main-fontfamily: sans-serif;
  --aux-fader-handle-accent: #BD2AB6;
  --aux-knob-accent: #BD2AB6;
  --aux-logo-accent: #BD2AB6;
  --aux-meter-size: 19px;
  --aux-multimeter-reduce: 1px;
  --aux-scale-fontfamily: sans-serif;
  --aux-scale-fontsize: var(--aux-main-smallfontsize);
  --aux-scale-labelmarginvertical: 6px;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
p,
a,
img,
ul,
li,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

* {
  position: relative;
  box-sizing: border-box;
}

body,
html {
  background: var(--background) !important;
  color: var(--color) !important;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: sans-serif;
  font-weight: 400;
  font-size: var(--font-size);
  line-height: var(--line-height);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  min-width: 480px;
  min-height: 400px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#UI {
  background: radial-gradient(#fefaf6, #d3d6da);
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

svg#logo {
  fill: var(--color);
  width: 96px;
  height: 14px;
}

.clipping>path {
  fill: var(--color-clip);
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  justify-content: space-between;
}

.aux-fader {
  height: 400px;
  margin: 0 8px !important;
}

.aux-fader.aux-left {
  grid-template-areas:
    'value .'
    'track scale'
    'label .' !important;
}

.aux-fader > .aux-track {
  background: var(--background);
  border-radius: 8px / 3px;
}

.aux-fader > .aux-track > .aux-handle {
  border-radius: 8px / 3px;
  background: radial-gradient(circle at 15% 12%, #ffffff 0%, #eaeaea 62%, #e3e3e3 64%, #dcdcdc 100%);
  box-shadow:
    1px 3px 8px rgba(0, 0, 0, 0.25),
    2px 6px 64px rgba(0, 0, 0, 0.2);
}

.aux-fader > .aux-track > .aux-handle::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--aux-fader-handle-accent);
}

.aux-scale.aux-vertical > .aux-dot {
  width: 3px;
  height: 1px;
}

.aux-scale.aux-left > .aux-dot {
  left: 0;
}

.aux-scale > .aux-dot {
  background: #111;
  opacity: 0.25;
}

.aux-scale > .aux-dot {
  position: absolute;
  z-index: 3;
}


.aux-knob {
  background: var(--background);
  border-radius: 50%;
  overflow: visible !important;
}

.aux-knob::before {
  background: radial-gradient(circle at 33% 0%, #ffffff 0%, #eaeaea 62%, #e3e3e3 64%, #dcdcdc 100%);
  box-shadow:
    1px 3px 8px rgba(0, 0, 0, 0.1),
    2px 6px 8px rgba(0, 0, 0, 0.1),
    3px 9px 8px rgba(0, 0, 0, 0.1),
    4px 12px 64px rgba(0, 0, 0, 0.2);
}

.aux-knob::after {
  width: 40%;
  height: 40%;
  background: radial-gradient(circle at 60% 100%, #ffffff 0%, #eaeaea 62%, #e3e3e3 64%, #dcdcdc 100%);
}

.aux-knob .aux-base {
  fill: rgba(255, 255, 255, 0.6);
}

.aux-knob .aux-value {
  fill: var(--aux-knob-accent);
}

.aux-knob .aux-hand {
  fill: var(--aux-knob-accent);
}
.ChannelMeter {
  align-self: center;
  height: 400px;
  margin: 0 20px;
}
.aux-levelmeter {
  margin: 0 !important;
}

.aux-levelmeter > .aux-bar {
  border-radius: 50% / 3px;
  border: 6px solid var(--background);
}


:focus {
  outline:none;
}

input {
  font-size: 120%;
  padding: 15px;
  margin: 5px;
  border:1px solid #dcdcdc;
	border-radius:6px;
  box-shadow:inset 0px 1px 0px 0px #ffffff;
  text-shadow:0px 1px 0px #ffffff;

}

input {
  text-indent: 5px;
}

button {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
	background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color: #ffffff;
	border-radius: 6px;
	border: 1px solid #dcdcdc;
	color: #666666;
  font-size: 120%;
	font-weight: bold;
	padding: 5px 24px;
  margin: 5px;
	text-decoration: none;
	text-shadow:0px 1px 0px #ffffff;
}

.formContainer span {
  display: block;
  padding: 20px;
  margin-top: -80px;
  max-width: 100%;
}

.formContainer .form {
  display: flex;
}
.aux-logo {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" style="color:slate;" width="96" height="14" viewBox="0 0 192 28" version="1.1"><defs id="defs4" /><path d="M 36,0 36,8 8,8 8,28 40,28 40,0 Z M 0,8 0,16 4,16 4,8 Z m 44,0 0,20 32,0 0,-4 -28,0 0,-4 28,0 0,-12 z m 36,0 0,20 32,0 0,-20 -4,0 0,16 -24,0 0,-16 z m 36,0 0,12 28,0 0,4 -28,0 0,4 28,0 4,0 0,-12 -28,0 0,-4 28,0 0,-4 z m 36,0 0,20 32,0 0,-20 z m 36,0 0,8 4,0 0,-8 z m -176,4 24,0 0,12 -24,0 z m 36,0 24,0 0,4 -24,0 z m 108,0 24,0 0,12 -24,0 z m -156,8 0,8 4,0 0,-8 z m 188,0 0,8 4,0 0,-8 z" /></svg>');
  height: 40px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: fixed;
  bottom: 20px;
  left: 0;
}